
import userAction from './userAction';
import commonAction from './commonAction';
import cartAction from './cartAction';

const rootAction = {
    userAction,
    commonAction,
    cartAction
}

export default rootAction